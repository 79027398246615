<template>
  <v-card>
    <v-card-title>
      <v-dialog v-model="dialogRegenerate" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text style="color: white;">
            Procesando
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Editar {{ editedItem.sample }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="editedItem.data_matrix_key"
                    label="Data Matrix Key"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="editedItem.data_matrix_key_value"
                    label="Data Matrix Key Value"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >¿Seguro que quieres eliminar la muestra
            {{ editedItem.sample }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancelar</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Eliminar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-select
          v-model="selectedLang"
          :items="['es', 'po', 'en']"
          label="Language"
          persistent-hint
          return-object
          single-line
        ></v-select>
        <v-btn @click="regenerateData('en')" depressed color="success">
          Regenerar
        </v-btn>
        <v-btn
          @click="
            $router.push({
              name: 'SampleGXDrugsResults',
              params: { sampleId: sampleId }
            })
          "
          depressed
          color="info"
        >
          Medicamentos
        </v-btn>
        <v-btn
          @click="
            $router.push({
              name: 'SampleGXGenesResults',
              params: { sampleId: sampleId }
            })
          "
          depressed
          color="primary"
        >
          Genes
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="searchableFields"
          v-model="activeFilter"
          item-text="text"
          item-value="value"
          label="Campos"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="filterSearch"
          append-icon="mdi-magnify"
          label="Filtrar"
        ></v-text-field>
      </v-col>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selectedFields"
          :items="allFields"
          label="Columnas"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </v-row>
    <v-data-table
      :headers="selectedHeaders"
      :items="sampleGXResults"
      :options.sync="options"
      multi-sort
      :server-items-length="totalSampleGXResults"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
export default {
  props: ["sampleId"],
  data() {
    return {
      selectedLang: "es",
      totalSampleGXResults: 0,
      sampleGXResults: [],
      filterSearch: null,
      loading: true,
      activeFilter: "data_matrix_key",
      options: {},
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
      dialogRegenerate: false,
      editedItem: {},
      selectedFields: ["Data Matrix Key", "Data Matrix Value", "Acciones"],
      headers: [
        {
          text: "Muestra",
          align: "start",
          sortable: true,
          searchable: true,
          value: "sample"
        },
        {
          text: "Data Matrix Key",
          value: "data_matrix_key",
          sortable: false,
          searchable: true
        },
        {
          text: "Data Matrix Value",
          value: "data_matrix_key_value",
          sortable: true,
          searchable: true
        },

        { text: "Acciones", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    searchableFields() {
      return this.selectedHeaders.filter(h => h.searchable);
    },
    allFields() {
      return this.headers.map(h => h.text);
    },
    selectedHeaders() {
      return this.headers.filter(h => {
        return this.selectedFields.includes(h.text);
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    filterSearch: {
      handler() {
        this.getDataFromApi();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    regenerateData() {
      let self = this;
      self.dialogRegenerate = true;
      fetch(`${process.env.VUE_APP_N8N_REGENERATE}?lang=${self.selectedLang}`, {
        mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify({ sample: self.sampleId })
      })
        .then(res => {
          self.$alertify.success("Datos regenerados");
        })
        .catch(err => {
          self.$alertify.success("Ha ocurrido un error");
        })
        .then(() => {
          self.dialogRegenerate = false;
          self.getDataFromApi();
        });
    },
    editItem(item) {
      this.editedIndex = this.sampleGXResults.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.sampleGXResults.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let self = this;
      Strapi.delete(`/gx-results/${this.editedItem.id}`)
        .then(() => {
          self.$alertify.success("Muestra eliminada");
        })
        .catch(() => {
          self.$alertify.error("Ha ocurrido un error");
        })
        .then(() => {
          self.closeDelete();
          self.getDataFromApi();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let self = this;
      Strapi.put(`/gx-results/${this.editedItem.id}`, self.editedItem)
        .then(() => {
          self.$alertify.success("Muestra actualizada");
        })
        .catch(() => {
          self.$alertify.error("Ha ocurrido un error");
        })
        .then(() => {
          self.close();
          self.getDataFromApi();
        });
    },
    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const _sort = sortBy
        .map(function(e, i) {
          return `${e}:${(sortDesc[i] && "DESC") || "ASC"}`;
        })
        .join(",");
      const qs = require("qs");

      let params = {
        _start: (page - 1) * itemsPerPage,
        _limit: itemsPerPage,
        _where: {
          sample: this.sampleId
        }
      };

      if (sortBy.length > 0) {
        params._sort = _sort;
      }

      if (this.filterSearch) {
        params._where = {
          _or: [
            { FIELD: this.filterSearch, sample: this.sampleId },
            { FIELD_contains: this.filterSearch, sample: this.sampleId }
          ]
        };
      }

      const query = qs
        .stringify(params, {
          encodeValuesOnly: true
        })
        .replace(/FIELD/g, this.activeFilter);

      Strapi.get(`/gx-results?${query}`).then(res => {
        this.sampleGXResults = res.data;
        Strapi.get(`/gx-results/count?${query}`).then(resCount => {
          this.totalSampleGXResults = resCount.data;
          this.loading = false;
        });
      });
    }
  }
};
</script>
<style lang="scss"></style>
